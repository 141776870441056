/*
 * page.less - Style top level page items
 */

#tabs {
  // Remove the space under the top navbar
  margin-top: 0;

  // Give the page contents square corners
  border-radius: 0;

  // Set the background colour
  background-color: @tabs-bg;

  // Provide smooth scrolling on iOS
  -webkit-overflow-scrolling: touch;
}

// Make the page contents full height minus navbar, padding and border
#main > .row-offcanvas {
  height: calc(100vh - @navbar-height - 4px - (@padding-large-vertical * 2)) !important;
}

// Use a margin instead of padding to space out the page contents
#tabs {
  padding: 0;
}
#tabcontent > .tab-pane > .panel {
  margin-left: @form-outer-margin;
  margin-right: @form-outer-margin;
  margin-bottom: @form-outer-margin;
}

// Add some box-shadows under the navbar and at the side of the menu
.tab-pane > .panel {
  box-shadow: 0 0 4px @panel-default-border;
}
#main > .row {
  box-shadow: inset 4px 4px 4px -4px @panel-default-border;
}

// Ensure the correct colour is used for the form title
.tab-pane > .panel > .panel-heading a {
  color: inherit;
}

// Ensure panel does not extend outside page
#tabs .panel-body.row {
  margin: 0;

  > div {
    padding: 0;
  }
}
