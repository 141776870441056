/*
 * login.less - Styles login screen
 */

// Only display the body and login box on the screen
.login > :not(.modal) {
  display: none !important;
}

// Set background colour / image
.login {
  background-image: @login-background-image;
  background-size: cover;
  height: 100vh;

  .modal {
    background-color: @login-background-image-overlay;
  }
}

// Make the login buttons take up the full width
.login .modal-footer {
  display: flex;

  .btn {
    width: 100%;

    + .btn {
      margin: @padding-small-vertical 0;
    }
  }
}

// Set the brand on the login screen
.login .modal .modal-title {
  background-image: @login-logo-image;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: if((@login-logo-image = none), unset, 64px);
  text-indent: if((@login-logo-image = none), 0, -262144px);
}

// Add ProCentral logo
.login .modal {
  background-image: url(/images/procentral-logo-dark.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 40%;
}

// Clean up the shadow and border around the login box
.login .modal-content {
  border: none;
  box-shadow: 5px 5px 15px fade(darken(@brand-primary, 10%), 30%);
}
