/*
 * board.less - Board specific styles
 */

// Evenly space the contents of the board
.board .board-action {
  border: none;
  margin-bottom: 0;
  padding: @board-action-padding;
}

// Put each action in a rounded box with the title above
.board .board-action {
  .panel-heading {
    background-color: transparent;
    border-bottom: none;
  }

  .panel-body {
    background-color: @board-action-bg;
    border: 1px solid @board-action-border-color;
    border-radius: @border-radius-base;
    margin-top: @board-action-padding / 2;
    padding: @board-action-padding;
  }
}

// Reduce the padding around filter boxes
.board .board-action .filter-box {
  margin: 0 0 @board-action-padding 0;

  .col-sm-10.col-xs-12 {
    padding: 0;
  }
}

// Hide previous / next buttons on filter boxes
.board .board-action .filter-box .col-sm-2.pull-right {
  display: none;
}

// Make the table headers and lines transparent
.board .board-action .table > * > tr {
  background-color: transparent !important;

  > th, > td {
    background-color: transparent !important;
  }
}

// Remove the selection state column from tables
.board .board-action .table .selection-state {
  display: None;
}

// Make the headers less prominent
.board .board-action .table th > label {
  color: @board-action-label-color;
}

// Remove the borders between rows
.board .board-action .table {
  border: none;
}

// Reduce the border below the header line
.board .board-action .table th {
  border-bottom: 1px solid @board-action-border-color;
}

// Don't indicate lines can be selected
.board .board-action .table td {
  cursor: default;
}

// Make form labels less prominent
.board td.form-label {
  color: @board-action-label-color;
}

// Make form fields appear readonly
.board {
  td.form-callto,
  td.form-date,
  td.form-datetime,
  td.form-email,
  td.form-float,
  td.form-integer,
  td.form-many2one,
  td.form-reference,
  td.form-sip,
  td.form-url {
    // width: unset !important;

    .form-control {
      background-color: transparent;
      border: none;
      box-shadow: none;
      cursor: default;
      // font-size: 200%;
      // height: unset !important;
      // text-align: center;
      margin: 0;
    }
  }
}

// Remove number selector arrows
.board {
  td.form-float,
  td.form-integer {
    .form-control {
      appearance: textfield;
    }
  }
}

// Centre text in fields that are centred
.board {
  td.form-callto,
  td.form-date,
  td.form-datetime,
  td.form-email,
  td.form-float,
  td.form-integer,
  td.form-many2one,
  td.form-reference,
  td.form-sip,
  td.form-url {
    &[style*="text-align: center"] .form-control {
      text-align: center;
    }
  }
}
