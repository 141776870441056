/*
 * menu.less - Style the main menu on the left
 */

// Allow customization of the menu colours
#menu {
  background-color: @menu-bg;
  color: @menu-color;

  img {
    filter: @menu-icon-color-filter;
  }
}

// Style the border to match the navbar
#menu {
  border: 1px solid @panel-default-border;
  box-shadow: 0 0 8px @panel-default-border;
}

// Hide the top scrollbar
#menu div.scrollbar.responsive {
  display: none;
}

// Hide the selection state column
#menu col.selection-state {
  width: 0 !important;
}

// Make hiding and showing the sidebar smooth
#tabs,
#main > .row-offcanvas {
  transition: all 0.25s ease-in-out;
}

// Remove the gap between the menu and the navbar
#menu {
  margin-top: 0;
}

// Round the corners on the menu
#menu {
  border-top-right-radius: @border-radius-large;
  border-bottom-right-radius: @border-radius-large;
}

// Remove the padding around the menu
#menu {
  padding: 0;
}

// Add a gap below the side menu,
#menu {
  height: calc(100% - @form-outer-margin) !important;
}

// Give the menu a shadow to match the main part of the page
#menu {
  box-shadow: 0 0 4px @panel-default-border;
}

// Provide smooth scrolling on iOS
#menu {
  -webkit-overflow-scrolling: touch;
}
