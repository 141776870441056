/*
 * filter-box.less - Styling for the filter box
 */

// Normalise the spacing around the filter box
.filter-box {
  margin: @filter-box-padding @filter-box-padding 0 @filter-box-padding;

  > .row > * {
    padding-left: @filter-box-padding;
    padding-right: @filter-box-padding;
  }
}

// Ensure the filter box doesn't expand the window width
.filter-box > .row {
  margin: @filter-box-padding 0 0 0;

  &:first-child {
    margin-top: 0;
  }
}

// Distribute the items evenly across the filter box
.filter-box > .row {
  display: flex;

  > * {
    width: auto;
  }

  > :first-child {
    flex-grow: 1;
  }
}

// Add padding behind the search input icons
.filter-box .form-control[placeholder="Search"] {
  padding-right: (@line-height-computed + (@input-icon-padding-small * 2)) * 4;
  @media (max-width: @screen-xs-max) {
    padding-right: (@line-height-computed + (@input-icon-padding-small * 2)) * 2;
  }
}

// Ensure the button is wide enough for the text
.filter-box .input-group-sm > .input-group-btn > button.btn {
  width: auto;
}
