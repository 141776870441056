/*
 * calendar.less - Calendar specific styles
 */

.calendar {
  // Add a standard margin around the calendar toolbar
  > .fc-toolbar {
    margin: @padding-base-horizontal;
  }

  // Remove the scrollbar from week view
  .fc-time-grid {
    overflow: hidden;
  }
}
