/*
 * navbar.less - Style the navbar displayed at the top of the screen
 */

// Remove the bottom margin from all navbars
.navbar {
  margin-bottom: 0;
}

// Ensure the toggle doesn't increase the height of the navbar
.navbar-toggle {
  padding-top: (@navbar-height - @line-height-computed - 16px - 2px) / 2;
  padding-bottom: (@navbar-height - @line-height-computed - 16px - 2px) / 2;
}

// Ensure the logout button doesn't increase the height of the navbar
#user-logout > a {
  padding-top: (@navbar-height - @line-height-computed) / 2;
  padding-bottom: (@navbar-height - @line-height-computed) / 2;
}

// Position the search box centrally
#global-search {
  margin-top: (@navbar-height - @input-height-small) / 2;
  margin-bottom: (@navbar-height - @input-height-small) / 2;
}

// Hide the search box if the user-favourites is not there
#user-favorites:empty ~ input {
  display: none;
}

// Dont let tabs overlap the space below the navbar
.navbar .nav-tabs > li > a {
  border-bottom: none;

  &:focus,
  &:hover {
    border-bottom: none;
  }
}

// Ensure there is a border around the search box
#global-search-entry {
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color-top: @input-border;
  border-color-left: @input-border;
  border-color-right: @input-border;
}

// Add styling to the main navbar
nav.navbar-static-top {
  padding: @padding-large-vertical @padding-large-vertical @padding-large-vertical 0;

  > .container-fluid {
    background-color: @navbar-main-bg;
    border-top-right-radius: @border-radius-large;
    border-bottom-right-radius: @border-radius-large;
    border-top: 1px solid @panel-default-border;
    border-right: 1px solid @panel-default-border;
    border-bottom: 1px solid @panel-default-border;
    box-shadow: 0 0 8px @panel-default-border;
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Remove the space after the brand in the navbar
.navbar .navbar-brand {
  padding-right: 0;
}

// Use an image in the main navbar
#title {
  background-image: @brand-logo-image;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  width: if((@brand-logo-image = none), unset, @brand-logo-image-width);
  top: if((@brand-logo-image = none), 0, -8px);
  position: relative;
  text-indent: if((@brand-logo-image = none), 0, -262144px);
}
