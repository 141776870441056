/*
 * form-checkbox.less - Style checkbox input fields
 */

@brand-primary-escaped: escape(@brand-primary);

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: @input-bg;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  cursor: pointer;
  margin: 0;
  height: @line-height-computed;
  width: @line-height-computed !important;

  &.input-sm {
    height: floor(@font-size-small * @line-height-small);
    width: floor(@font-size-small * @line-height-small) !important;
  }

  &.input-lg {
    height: ceil(@font-size-large * @line-height-large);
    width: ceil(@font-size-large * @line-height-large) !important;
  }

  &:active, &:focus {
    border-color: @input-border-focus;
  }

  &:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="@{brand-primary-escaped}" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &[readonly], &[disabled] {
    background-color: @input-bg-disabled;
  }
}
