/*
 * tree-responsive.less - Don't collapse list views on small screens
 */

// Reset the table layout
@media screen and (max-width: @screen-xs-max) {
  .treeview > table.responsive {
    display: table !important;
  }
  .treeview > table.responsive > thead {
    display: table-header-group !important;
  }
  .treeview > table.responsive > tbody {
    display: table-row-group !important;
  }
  .treeview > table.responsive > tfoot {
    display: table-footer-group !important;
  }
  .treeview > table.responsive > thead > tr,
  .treeview > table.responsive > tbody > tr,
  .treeview > table.responsive > tfoot > tr {
    display: table-row !important;
  }
  .treeview > table.responsive > thead > tr > th:not(.invisible),
  .treeview > table.responsive > tbody > tr > td:not(.invisible),
  .treeview > table.responsive > tfoot > tr > td:not(.invisible) {
    display: table-cell !important;
  }

  .treeview > table.responsive-header > thead > tr {
    position: inherit;
  }

  .treeview > table.responsive-header {
    > tbody > tr {
        border: none;
    }
    > tbody > tr > td,
    > tfoot > tr > td {
      border-top: 1px solid #DDD;
      border-bottom: none;
    }
    > tbody > tr,
    > tfoot > tr {
      > td[data-title] {
        > span[aria-hidden="true"] {
          display: none;
        }

        &:before {
          content: none;
        }
      }
    }
  }
}

// Only display some of the columns
@media screen and (max-width: @screen-xs-max) {
  .treeview > table.responsive > colgroup > col {
    display: none !important;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      display: table-column !important;
    }
  }
}

// Hide the footer
@media screen and (max-width: @screen-xs-max) {
  .treeview > table.responsive tfoot {
    display: none !important;
  }
}
