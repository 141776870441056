/*
 * form-widgets-rounded.less - Styles to ensure widgets have rounded corners
 */

// Round the corners of the input and select boxes
.form-callto,
.form-char,
.form-date,
.form-datetime,
.form-email,
.form-float,
.form-integer,
.form-many2one,
.form-multiselection,
.form-password,
.form-pyson
.form-selection,
.form-sip,
.form-time,
.form-timedelta,
.form-url {
  input,
  select {
    border-radius: @input-border-radius-small !important;
  }
}

// Round the corners of the text input in the filter box
.filter-box input.form-control {
  border-top-right-radius: @input-border-radius-small !important;
  border-bottom-right-radius: @input-border-radius-small !important;
}
