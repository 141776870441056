/*
 * wizard.less - Wizard specific styles
 */

// Standardise the size of the button icons
.wizard-form,
.wizard-dialog {
  .modal-footer .btn img.icon {
    height: @input-icon-base;
  }
}

// Add a gap between the icon and text
.wizard-form,
.wizard-dialog {
  .modal-footer .btn img.icon {
    padding-right: @button-icon-text-spacing;
  }
}
